import React, {
  ReactElement,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef
} from "react";
import Page from "../../components/Page";
import FullPageLoader from "../../components/FullPageLoader";
import Client from "../../types/Client";
import RequestHandler from "octa-front-sdk/dist/RequestHandler";
import { cleanPhoneNumber, formatDate } from "octa-front-sdk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Color from "octa-front-sdk/dist/enums/Color";
import InactiveClientsPageStyles from "./styles";

const InactiveClientsPage: React.FC = (): ReactElement => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState<Client[]>([]);
  const [filteredGroup, setFilteredGroup] = useState("");
  const [whatsappMessage, setWhatsappMessage] = useState(
    "Olá! Você não faz um pedido conosco há um tempo. Que tal fazer um novo pedido?"
  );

  const calcDifferenceOfDates = (lastDate: Date): number => {
    const msInADay = 1000 * 60 * 60 * 24;
    const lastDateInMs = new Date(lastDate).getTime();
    const currentTime = Date.now();

    const difference = currentTime - lastDateInMs;

    return Math.round(difference / msInADay);
  };

  const fetchClients = useCallback((): Promise<void> => {
    return RequestHandler.get(`clients/recovery`).then((response): void => {
      const data = response.data;

      data.forEach(
        (client: { lastOrderDate: Date; lastOrderDays: string }): void => {
          const diffDays = calcDifferenceOfDates(client.lastOrderDate);
          const groups = [30, 45, 60, 90, 120];

          const group = groups.reduce((max, currentGroup): number => {
            if (diffDays > currentGroup) return currentGroup;

            return max;
          }, 0);

          client.lastOrderDays = `Há mais de ${group} dias`;
        }
      );

      setClients(data);
    });
  }, []);

  const flagAsContacted = useCallback(
    (id: number): Promise<void> => {
      return RequestHandler.post(`clients/${id}/contact`).then((): void => {
        const date = new Date();
        const updatedClientsList = [...clients];

        const index = updatedClientsList.findIndex(
          (client): boolean => client.id === id
        );

        if (index > -1) {
          updatedClientsList[index] = {
            ...updatedClientsList[index],
            lastContactDate: date
          };

          setClients(updatedClientsList);
        }
      });
    },
    [clients]
  );

  useEffect((): void => {
    setIsLoading(true);

    fetchClients().finally((): void => {
      setIsLoading(false);
    });
  }, [fetchClients]);

  useEffect((): void => {
    const localMessage = localStorage.getItem("whatsappMessage");

    if (!!localMessage) setWhatsappMessage(localMessage);
  }, []);

  function handleClientContact(id: number, phone: string): void {
    if (textareaRef.current) {
      flagAsContacted(id);

      const msg = textareaRef.current.value;

      window.open(`https://wa.me/55${phone}?text=${msg}`, "_blank");
    }
  }

  function handleSaveWhatsappNewMessage(): void {
    localStorage.setItem("whatsappMessage", whatsappMessage);
  }

  // @ts-ignore
  function groupBy(array, key): { [key: string]: Client[] } {
    // @ts-ignore
    return array.reduce((total, cv): Client[] => {
      (total[cv[key]] = total[cv[key]] || []).push(cv);
      return total;
    }, {});
  }

  const renderClientsList = useMemo((): ReactElement => {
    if (clients.length === 0) return <p>Nenhum cliente inativo.</p>;

    const array = groupBy(clients, "lastOrderDays");

    return (
      <>
        {Object.keys(array).map(
          (key): ReactElement => (
            <InactiveClientsPageStyles.List
              isFiltered={filteredGroup === "" || key.includes(filteredGroup)}
            >
              <div className="list-header">
                <h2>{key}</h2>
              </div>
              <div>
                <ul>
                  {array[key].map(
                    (client): ReactElement => {
                      const {
                        name,
                        ordersCount,
                        lastContactDate,
                        id,
                        phone
                      } = client;
                      const correctPhone = cleanPhoneNumber(phone);
                      const formattedDate = formatDate(
                        new Date(lastContactDate)
                      )
                        .split("-")
                        .reverse()
                        .join("/");

                      return (
                        <li>
                          <InactiveClientsPageStyles.Client>
                            <InactiveClientsPageStyles.Details>
                              <p>{name}</p>

                              <span>Pedidos realizados: {ordersCount}</span>

                              {lastContactDate && (
                                <span>Último contato: {formattedDate}</span>
                              )}
                            </InactiveClientsPageStyles.Details>

                            <div>
                              <InactiveClientsPageStyles.WhatsappButton
                                type="button"
                                onClick={(): void =>
                                  handleClientContact(id, correctPhone)
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faWhatsapp}
                                  color={Color.White}
                                  size="2x"
                                />
                              </InactiveClientsPageStyles.WhatsappButton>
                            </div>
                          </InactiveClientsPageStyles.Client>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            </InactiveClientsPageStyles.List>
          )
        )}
      </>
    );
    // eslint-disable-next-line
  }, [clients, flagAsContacted, filteredGroup])

  return (
    <Page>
      {isLoading ? (
        <FullPageLoader />
      ) : (
        <InactiveClientsPageStyles.Container>
          <InactiveClientsPageStyles.Message>
            <textarea
              ref={textareaRef}
              value={whatsappMessage}
              onChange={(e): void => setWhatsappMessage(e.target.value)}
            />
            <button type="button" onClick={handleSaveWhatsappNewMessage}>
              Salvar nova mensagem
            </button>
          </InactiveClientsPageStyles.Message>

          <InactiveClientsPageStyles.TitleAndFilter>
            <h1>Clientes Inativos</h1>
            <select onChange={(e): void => setFilteredGroup(e.target.value)}>
              <option value="">Todos</option>
              <option value="30">30 dias</option>
              <option value="45">45 dias</option>
              <option value="60">60 dias</option>
              <option value="90">90 dias</option>
              <option value="120">120 dias</option>
            </select>
          </InactiveClientsPageStyles.TitleAndFilter>

          {renderClientsList}
        </InactiveClientsPageStyles.Container>
      )}
    </Page>
  );
};

export default InactiveClientsPage;

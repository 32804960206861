import React, { ReactElement } from "react";
import SideMenuStyles from "./styles";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  closeMenu: Function;
}

const SideMenu: React.FC<IProps> = (props): ReactElement => {
  const { closeMenu } = props;

  const links = [
    { path: "/", label: "Início" },
    {
      path: "/clientes-inativos",
      label: "Clientes inativos",
      comingSoon: false
    }
  ];

  return (
    <>
      <SideMenuStyles.Background onClick={(): void => closeMenu()} />

      <SideMenuStyles.Container>
        <FontAwesomeIcon icon={faTimes} onClick={(): void => closeMenu()} />

        <SideMenuStyles.Links>
          {links.map(
            ({ path, label, comingSoon }, i): ReactElement => (
              <SideMenuStyles.MenuLink
                key={i}
                comingSoon={comingSoon}
                onClick={(): void => closeMenu()}
              >
                <Link to={path}>
                  {label}
                  {comingSoon && <span>em breve</span>}
                </Link>
              </SideMenuStyles.MenuLink>
            )
          )}

          <SideMenuStyles.MenuLink>
            <Link to="logout">Sair</Link>
          </SideMenuStyles.MenuLink>
        </SideMenuStyles.Links>
      </SideMenuStyles.Container>
    </>
  );
};

export default SideMenu;

import styled from "styled-components";
import Color from "octa-front-sdk/dist/enums/Color";
import {
  GroupedListContainer,
  GroupedListContent
} from "octa-front-sdk/dist/components/GroupedList/styles";
import MediaQuery from "octa-front-sdk/dist/enums/MediaQuery";
import FontSize from "octa-front-sdk/dist/enums/FontSize";

const WhatsappButton = styled.button`
  background-color: ${Color.Green500};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  border: none;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

const Message = styled.div`
  padding: auto 15px;
  margin: 20px;

  ${MediaQuery.BiggerThanSmall} {
    margin: auto;
  }

  textarea {
    width: 100%;
    resize: none;
    height: 8rem;
    padding: 0.5rem;
    border-radius: 8px;
    box-sizing: border-box;
  }

  button {
    width: 100%;
    display: block;
    cursor: pointer;
    transition: 0.2s;
    border: none;
    color: #fff;
    padding: 1rem;
    border-radius: 8px;
    margin: auto;
    background: linear-gradient(
      45deg,
      ${Color.MeuDeliveryCyan},
      ${Color.MeuDeliveryBlue}
    );

    &:hover {
      filter: brightness(0.9);
    }

    &:disabled {
      background: none;
      background-color: ${Color.Grey400};
    }
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: ${FontSize.NormalX};
    font-weight: bold;
  }

  span {
    font-size: ${FontSize.Small};
    color: ${Color.Grey600};
    margin-left: 5px;
    margin-bottom: 3px;
  }
`;

const Client = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  cursor: pointer;

  &:hover {
    background-color: ${Color.Grey100};
  }

  path {
    fill: ${Color.White};
  }
`;

interface IList {
  isFiltered: boolean;
}

// eslint-disable-next-line
const List = styled.div<IList>`
  box-sizing: border-box;
  display: ${(props): string => (props.isFiltered ? "block" : "none")};

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      border-bottom: 1px solid ${Color.Grey300};
    }
  }

  .list-header {
    background-color: ${Color.Grey300};
    padding: 4px 16px;

    h2 {
      font-size: 1rem;
    }
  }
`;

const TitleAndFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  margin: 2rem 0;
  padding: 0 8px;

  h1 {
    font-size: 1.25rem;
  }

  select {
    display: block;
    width: 100%;
    max-width: 50%;
    height: 52px;
    padding: 1rem;
    border-radius: 8px;
  }
`;

const Container = styled.div`
  margin-top: 70px;

  ${MediaQuery.BiggerThanSmall} {
    max-width: 480px;
    margin: 70px auto 0;
  }

  & > h1,
  & > p {
    margin: 20px;

    ${MediaQuery.BiggerThanSmall} {
      max-width: 480px;
      margin: 40px auto;
    }
  }

  ${GroupedListContent} {
    &:not(:last-of-type) {
      border-bottom: 1px solid ${Color.Grey300};
    }
  }

  ${GroupedListContainer} {
    border-bottom: 1px solid ${Color.Grey300};

    ${MediaQuery.BiggerThanSmall} {
      border: 1px solid ${Color.Grey300};
    }
  }
`;

const InactiveClientsPageStyles = {
  WhatsappButton,
  Message,
  Details,
  Client,
  List,
  TitleAndFilter,
  Container
};

export default InactiveClientsPageStyles;

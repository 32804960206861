import React, { ReactElement, useState, useEffect } from "react";
import TopBarStyles from "./styles";
import logo from "../../assets/images/logo.svg";
import SideMenu from "../SideMenu";
import Color from "octa-front-sdk/dist/enums/Color";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const TopBar: React.FC = (): ReactElement => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect((): void => {
    document.documentElement.style.overflow = isMenuOpen ? "hidden" : "initial";
  }, [isMenuOpen]);

  return (
    <>
      <TopBarStyles.Container>
        <FontAwesomeIcon
          icon={faBars}
          color={Color.Grey700}
          size="lg"
          onClick={(): void => setIsMenuOpen(true)}
        />

        <img src={logo} alt="logo" />
      </TopBarStyles.Container>

      {isMenuOpen && <SideMenu closeMenu={(): void => setIsMenuOpen(false)} />}
    </>
  );
};

export default TopBar;

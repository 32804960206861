import styled, { css } from "styled-components";
import Color from "octa-front-sdk/dist/enums/Color";
import MediaQuery from "octa-front-sdk/dist/enums/MediaQuery";
import FontSize from "octa-front-sdk/dist/enums/FontSize";

const NoDataMessage = styled.p`
  text-align: center;
  font-size: ${FontSize.Normal};

  ${MediaQuery.BiggerThanSmall} {
    font-size: ${FontSize.Big};
  }
`;

const LatestUpdate = styled.p`
  text-align: right;
  font-size: ${FontSize.SmallX};
  max-width: 800px;
  margin: 10px auto;

  &,
  strong {
    color: ${Color.Blue500};
  }
`;

const Income = styled.p`
  font-size: ${FontSize.NormalX};
  margin-top: 0;
  margin-bottom: 15px;

  &,
  strong {
    color: ${Color.Grey700};
  }

  ${MediaQuery.BiggerThanSmall} {
    margin-bottom: 20px;
  }
`;

const indicatorStyles = css`
  color: ${Color.White};
  text-align: center;
`;

const IndicatorValue = styled.span`
  ${indicatorStyles};

  font-size: ${FontSize.Big};
  font-weight: bold;
  line-height: 1;

  ${MediaQuery.BiggerThanSmall} {
    font-size: ${FontSize.Huge};
  }
`;

const IndicatorLabel = styled.span`
  ${indicatorStyles};

  font-size: ${FontSize.SmallX};

  ${MediaQuery.BiggerThanSmall} {
    font-size: ${FontSize.Big};
  }
`;

const Indicator = styled.div`
  padding: 10px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: linear-gradient(45deg, ${Color.Red400}, ${Color.Red700});

  &:not(:last-of-type) {
    background: linear-gradient(45deg, ${Color.Green400}, ${Color.Green700});
    margin-right: 10px;
  }

  ${MediaQuery.BiggerThanSmall} {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    flex-flow: row-reverse;
    max-width: 220px;

    &:not(:last-of-type) {
      margin-right: 30px;
    }
  }
`;

const IndicatorsContainer = styled.div`
  display: flex;
`;

const CardTitle = styled.h1`
  margin: 0 0 15px;
  font-size: ${FontSize.NormalX};
  display: inline-block;

  ${MediaQuery.BiggerThanSmall} {
    font-size: ${FontSize.Big};
    margin-bottom: 25px;
  }
`;

const CardSubtitle = styled.h2`
  display: inline-block;
  margin: 0 0 15px;
  font-size: ${FontSize.Normal};
  margin-left: 5px;
  font-weight: normal;
  color: ${Color.Grey700};

  ${MediaQuery.BiggerThanSmall} {
    font-size: ${FontSize.NormalX};
    margin-bottom: 25px;
  }
`;

const Card = styled.div`
  border-radius: 5px;
  border: 1px solid ${Color.Grey200};
  padding: 10px;
  max-width: 800px;
  margin: 0 auto 30px;
  box-sizing: border-box;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.16),
    0px -1px 5px -5px rgba(0, 0, 0, 0.16);
`;

const Container = styled.div`
  margin-top: 50px;
  padding: 20px 6.25%;

  ${MediaQuery.BiggerThanSmall} {
    padding: 20px 12.5%;
  }
`;

const DashboardPageStyles = {
  NoDataMessage,
  LatestUpdate,
  Income,
  IndicatorValue,
  IndicatorLabel,
  Indicator,
  IndicatorsContainer,
  CardTitle,
  CardSubtitle,
  Card,
  Container
};

export default DashboardPageStyles;

import { createGlobalStyle } from "styled-components";
import FontSize from "octa-front-sdk/dist/enums/FontSize";
import Color from "octa-front-sdk/dist/enums/Color";
import ToastStyles from "octa-front-sdk/dist/components/Toast/styles";

const GlobalStyle = createGlobalStyle`
  * {
    font-family: "Roboto", sans-serif;
    color: ${Color.Grey900};
  }

  html,
  body,
  #root {
    height: 100%;
  }

  p {
    font-size: ${FontSize.Normal};
  }



  .fb-login {
    width: 100%;
    background-color: ${Color.White};
    color: ${Color.FacebookBlue};
    border: 1px solid ${Color.FacebookBlue};
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;
    font-size: ${FontSize.SmallX};
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: ${FontSize.NormalX};
      margin-right: 10px
    }
  }

  ${ToastStyles.Container} {
    background: linear-gradient(to top right, ${Color.MeuDeliveryCyan}, ${Color.MeuDeliveryBlue});
  } 

  ${ToastStyles.Message} {
    color: ${Color.White};
  }
`;

export default GlobalStyle;

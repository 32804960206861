import React, { ReactElement, useEffect, useState, useCallback } from "react";
import DashboardPageStyles from "./styles";
import {
  formatMoney,
  getHoursAndMinutesFromDate,
  formatDate
} from "octa-front-sdk/dist/Helpers";
import Page from "../../components/Page";
import RequestHandler from "octa-front-sdk/dist/RequestHandler";
import Summary from "../../types/Summary";
import SummaryType from "../../enums/SummaryType";
import LastMonthsChart from "./LastMonthsChart";
import LastYearsChart from "./LastYearsChart";
import FullPageLoader from "../../components/FullPageLoader";
import Digest from "./Digest";

const DashboardPage: React.FC = (): ReactElement => {
  const [todaysSummaries, setTodaysSummaries] = useState<{
    [key: string]: Summary;
  }>({});
  const [lastMonthsSummaries, setLastMonthsSummaries] = useState<Summary[]>([]);
  const [lastYearsSummaries, setLastYearsSummaries] = useState<Summary[]>([]);
  const [lastUpdate, setLastUpdate] = useState<Date>();
  const [isLoading, setLoading] = useState(true);

  const getIncome = useCallback((): number => {
    const summary = todaysSummaries[SummaryType.Paid];

    if (summary) return summary.value * 100;

    return 0;
  }, [todaysSummaries]);

  const getQuantity = useCallback(
    (summaryType: string): number => {
      const summary = todaysSummaries[summaryType];

      if (summary) return summary.quantity;

      return 0;
    },
    [todaysSummaries]
  );

  const fetchLastUpdate = useCallback((): Promise<void> => {
    return RequestHandler.get(`sales/summary/lastUpdate`).then(
      (response): void => {
        if (response.data.lastUpdate)
          setLastUpdate(new Date(response.data.lastUpdate));
      }
    );
  }, [setLastUpdate]);

  const fetchSummaries = useCallback((): Promise<void> => {
    return RequestHandler.get(
      `sales/summary?day=${formatDate(new Date())}`
    ).then((response): void => {
      const summaries: { [key: string]: Summary } = response.data;

      setTodaysSummaries(summaries);
    });
  }, []);

  const fetchDailySummary = useCallback((): Promise<void> => {
    const endDate = new Date();
    const startDate = new Date(endDate.getTime() - 29 * 24 * 60 * 60 * 1000);

    return RequestHandler.get(
      `sales/summary/daily?start=${formatDate(startDate)}&end=${formatDate(
        endDate
      )}&type=${SummaryType.Paid}`
    ).then((response): void => {
      setLastMonthsSummaries(response.data);
    });
  }, []);

  const fetchMonthlySummary = useCallback((): Promise<void> => {
    const endDate = new Date();
    const startDate = new Date(
      endDate.getTime() - 12 * 29 * 24 * 60 * 60 * 1000
    );

    return RequestHandler.get(
      `sales/summary/monthly?start=${formatDate(startDate)}&end=${formatDate(
        endDate
      )}&type=${SummaryType.Paid}`
    ).then((response): void => {
      setLastYearsSummaries(response.data);
    });
  }, []);

  useEffect((): void => {
    Promise.all([
      fetchLastUpdate(),
      fetchSummaries(),
      fetchDailySummary(),
      fetchMonthlySummary()
    ]).finally((): void => {
      setLoading(false);
    });
  }, [fetchLastUpdate, fetchSummaries, fetchDailySummary, fetchMonthlySummary]);

  return (
    <Page>
      {isLoading && <FullPageLoader />}

      <DashboardPageStyles.Container>
        {!lastUpdate ? (
          <DashboardPageStyles.NoDataMessage>
            Os dados da sua empresa ainda não foram sincronizados.
          </DashboardPageStyles.NoDataMessage>
        ) : (
          <>
            <DashboardPageStyles.LatestUpdate>
              {lastUpdate && (
                <>
                  Última atualização {lastUpdate.toLocaleDateString("pt-BR")},
                  às
                  <strong> {getHoursAndMinutesFromDate(lastUpdate)}</strong>.
                </>
              )}
            </DashboardPageStyles.LatestUpdate>

            <DashboardPageStyles.Card>
              <DashboardPageStyles.CardTitle>
                Seu caixa hoje
              </DashboardPageStyles.CardTitle>

              <DashboardPageStyles.Income>
                Faturamento: <strong>{formatMoney(getIncome())}</strong>
              </DashboardPageStyles.Income>

              <DashboardPageStyles.CardTitle>
                Vendas
              </DashboardPageStyles.CardTitle>

              <DashboardPageStyles.IndicatorsContainer>
                <DashboardPageStyles.Indicator>
                  <DashboardPageStyles.IndicatorValue>
                    {getQuantity(SummaryType.Paid)}
                  </DashboardPageStyles.IndicatorValue>

                  <DashboardPageStyles.IndicatorLabel>
                    Recebidas
                  </DashboardPageStyles.IndicatorLabel>
                </DashboardPageStyles.Indicator>

                <DashboardPageStyles.Indicator>
                  <DashboardPageStyles.IndicatorValue>
                    {getQuantity(SummaryType.Canceled)}
                  </DashboardPageStyles.IndicatorValue>

                  <DashboardPageStyles.IndicatorLabel>
                    Canceladas
                  </DashboardPageStyles.IndicatorLabel>
                </DashboardPageStyles.Indicator>
              </DashboardPageStyles.IndicatorsContainer>
            </DashboardPageStyles.Card>

            {lastMonthsSummaries.length > 0 && (
              <DashboardPageStyles.Card>
                <DashboardPageStyles.CardTitle>
                  Vendas
                </DashboardPageStyles.CardTitle>

                <DashboardPageStyles.CardSubtitle>
                  nos últimos 30 dias
                </DashboardPageStyles.CardSubtitle>

                <LastMonthsChart lastMonthsSummaries={lastMonthsSummaries} />

                <Digest summaries={lastMonthsSummaries} />
              </DashboardPageStyles.Card>
            )}

            {lastYearsSummaries.length > 0 && (
              <DashboardPageStyles.Card>
                <DashboardPageStyles.CardTitle>
                  Vendas
                </DashboardPageStyles.CardTitle>

                <DashboardPageStyles.CardSubtitle>
                  nos últimos 12 meses
                </DashboardPageStyles.CardSubtitle>

                <LastYearsChart lastYearsSummaries={lastYearsSummaries} />

                <Digest summaries={lastYearsSummaries} />
              </DashboardPageStyles.Card>
            )}
          </>
        )}
      </DashboardPageStyles.Container>
    </Page>
  );
};

export default DashboardPage;

import styled from "styled-components";
import Color from "octa-front-sdk/dist/enums/Color";

const Container = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: ${Color.White};
  border-bottom: 1px solid ${Color.Grey300};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;

  svg {
    position: fixed;
    left: 20px;
  }

  img {
    height: 100%;
    margin: 0 auto;
  }
`;

const TopBarStyles = { Container };

export default TopBarStyles;

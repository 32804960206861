import styled from "styled-components";
import Color from "octa-front-sdk/dist/enums/Color";

interface IMenuLink {
  comingSoon?: boolean;
}

// eslint-disable-next-line
const MenuLink = styled.li<IMenuLink>`
  pointer-events: ${({ comingSoon }): string =>
    comingSoon ? "none" : "initial"};

  &:not(:last-of-type):hover {
    background-color: ${Color.Grey100};
  }

  &:last-of-type {
    align-self: flex-end;
    margin-top: auto;

    a {
      color: ${Color.Grey500};
    }
  }

  a {
    padding: 20px;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;

    span {
      background-color: ${Color.MeuDeliveryCyan};
      color: ${Color.Blue50};
      padding: 3px 5px;
      border-radius: 3px;
      letter-spacing: 0.1rem;
    }
  }
`;

const Links = styled.ul`
  padding: 0;
  margin: 0;
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;

  li {
    &:not(:last-of-type):hover {
      background-color: ${Color.Grey100};
    }

    &:last-of-type {
      align-self: flex-end;
      margin-top: auto;

      ${MenuLink} {
        color: ${Color.Grey500};
      }
    }
  }
`;

const Background = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  background-color: ${Color.White};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;

  svg {
    margin: 10px 10px 10px auto;

    path {
      color: ${Color.Grey400};
    }
  }
`;

const SideMenuStyles = { MenuLink, Links, Background, Container };

export default SideMenuStyles;

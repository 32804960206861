import React, { ReactElement, useState, useCallback, useEffect } from "react";
import Summary from "../../types/Summary";
import { formatMoney, months } from "octa-front-sdk/dist/Helpers";
import Color from "octa-front-sdk/dist/enums/Color";
import { Bar } from "react-chartjs-2";
import DataPoint from "../../types/DataPoint";

interface IProps {
  lastYearsSummaries: Summary[];
}

const LastYearsChart: React.FC<IProps> = (props): ReactElement => {
  const lastYearsSummaries = props.lastYearsSummaries.sort((a, b): number => {
    return a.month.localeCompare(b.month);
  });
  const [width, setWidth] = useState(document.body.clientWidth);

  const handleResize = useCallback((): void => {
    setWidth(document.body.clientWidth);
  }, []);

  useEffect((): (() => void) => {
    window.addEventListener("resize", handleResize);

    return (): void => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  const data = {
    labels: lastYearsSummaries.map((s): string => {
      const month = parseInt(s.month.split("-")[1]) - 1;

      return `${months[month].substr(0, 3)}`;
    }),
    datasets: [
      {
        data: lastYearsSummaries.map((s): number =>
          parseFloat(s.value.toFixed(2))
        ),
        backgroundColor: Color.MeuDeliveryCyan,
        hoverBackgroundColor: Color.MeuDeliveryBlue,
        fill: false,
        pointBackgroundColor: "transparent",
        pointBorderColor: "transparent",
        lineTension: 0,
        borderWidth: 1
      }
    ]
  };

  return (
    <Bar
      data={data}
      options={{
        tooltips: {
          displayColors: false,
          callbacks: {
            title: (datasets: DataPoint[]): string => {
              const { value } = datasets[0];

              return `${formatMoney(+value * 100)}`;
            },
            label: (point: DataPoint): string => {
              const { index } = point;
              const [year, month] = lastYearsSummaries[index].month.split("-");
              const label = `${months[parseInt(month) - 1]}, ${year}`;

              return label;
            }
          }
        },
        legend: { display: false },
        scales: {
          xAxes: [
            {
              gridLines: { display: true },
              ticks: { maxRotation: 0, maxTicksLimit: width < 768 ? 6 : 12 }
            }
          ],
          yAxes: [
            {
              gridLines: { display: true },
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }}
    />
  );
};

export default LastYearsChart;

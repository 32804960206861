import React, { ReactElement, useCallback, useEffect, useState } from "react";
import Summary from "../../types/Summary";
import { formatMoney } from "octa-front-sdk/dist/Helpers";
import Color from "octa-front-sdk/dist/enums/Color";
import { Bar } from "react-chartjs-2";
import DataPoint from "../../types/DataPoint";

interface IProps {
  lastMonthsSummaries: Summary[];
}

const LastMonthsChart: React.FC<IProps> = (props): ReactElement => {
  const { lastMonthsSummaries } = props;
  const [width, setWidth] = useState(document.body.clientWidth);

  const handleResize = useCallback((): void => {
    setWidth(document.body.clientWidth);
  }, []);

  useEffect((): (() => void) => {
    window.addEventListener("resize", handleResize);

    return (): void => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  const data = {
    labels: lastMonthsSummaries.map((s): string => {
      return s.day.split("-").pop() || "";
    }),
    datasets: [
      {
        data: lastMonthsSummaries.map((s): number =>
          parseFloat(s.value.toFixed(2))
        ),
        backgroundColor: Color.MeuDeliveryCyan,
        hoverBackgroundColor: Color.MeuDeliveryBlue,
        fill: false,
        pointBackgroundColor: "transparent",
        pointBorderColor: "transparent",
        lineTension: 0,
        borderWidth: 1
      }
    ]
  };

  return (
    <Bar
      data={data}
      options={{
        tooltips: {
          displayColors: false,
          callbacks: {
            title: (datasets: DataPoint[]): string => {
              const { value } = datasets[0];

              return `${formatMoney(+value * 100)}`;
            },
            label: (point: DataPoint): string => {
              const { index } = point;
              const label = lastMonthsSummaries[index].day
                .split("-")
                .reverse()
                .join("/");

              return label;
            }
          }
        },
        legend: { display: false },
        scales: {
          xAxes: [
            {
              gridLines: { display: true },
              ticks: { maxRotation: 0, maxTicksLimit: width < 768 ? 6 : 30 }
            }
          ],
          yAxes: [
            {
              gridLines: { display: true },
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }}
    />
  );
};

export default LastMonthsChart;

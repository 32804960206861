import React, { ReactElement, useState, useEffect } from "react";
import { Normalize } from "styled-normalize";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import GlobalStyle from "./assets/GlobalStyle";
import DashboardPage from "./pages/dashboard";
import GlobalContext from "./contexts/GlobalContext";
import CustomRoute from "octa-front-sdk/dist/components/CustomRoute";
import LoginPage from "./pages/login";
import Toast from "octa-front-sdk/dist/components/Toast";
import RequestHandler from "octa-front-sdk/dist/RequestHandler";
import { AxiosError, AxiosResponse } from "axios";
import { getErrorResponseFirstMessage } from "octa-front-sdk/dist/Helpers";
import InactiveClientsPage from "./pages/inactive-clients";

const App: React.FC = (): ReactElement => {
  const [isLoggedIn, setLoggedIn] = useState();
  const [toastMessage, setToastMessage] = useState();

  useEffect((): void => {
    return setLoggedIn(!!localStorage.getItem("accessToken"));
  }, []);

  useEffect((): void => {
    RequestHandler.interceptors.response.use(
      undefined,
      (error: AxiosError): Promise<void | AxiosResponse> => {
        let toastMessage = "Ocorreu um erro. Tente novamente.";

        if (error.response && error.response.status === 404) {
          toastMessage = "Não encontrado.";
        } else if (error.response) {
          toastMessage = getErrorResponseFirstMessage(error.response);
        }

        setToastMessage(toastMessage);

        return Promise.reject(error);
      }
    );
  }, [setToastMessage]);

  return (
    <GlobalContext.Provider value={{ isLoggedIn, setLoggedIn }}>
      <Normalize />

      <GlobalStyle />

      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/"
            component={isLoggedIn ? DashboardPage : LoginPage}
          />

          <Route
            exact
            path="/clientes-inativos"
            component={InactiveClientsPage}
          />

          <Route
            exact
            path="/logout"
            component={(): ReactElement => {
              localStorage.clear();

              setLoggedIn(false);

              return <Redirect to="/" />;
            }}
          />

          <CustomRoute
            path="/login"
            isLoggedIn={isLoggedIn}
            isAnonymous
            component={LoginPage}
          />
        </Switch>

        <Toast setMessage={setToastMessage} message={toastMessage} />
      </BrowserRouter>
    </GlobalContext.Provider>
  );
};

export default App;

import styled from "styled-components";
import Color from "octa-front-sdk/dist/enums/Color";

const Message = styled.span`
  color: ${Color.White};
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  font-weight: lighter;
`;

const Container = styled.div`
  background-color: lightpink;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    45deg,
    ${Color.MeuDeliveryCyan},
    ${Color.MeuDeliveryBlue}
  );
  background-size: 250% 250%;
  animation: gradient 2s ease infinite;
  opacity: 0.95;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const FullPageLoaderStyles = { Message, Container };

export default FullPageLoaderStyles;

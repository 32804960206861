import React, { ReactElement, useState, useContext } from "react";
import LoginPageStyles from "./styles";
import Form from "octa-front-sdk/dist/components/Form";
import Input from "octa-front-sdk/dist/components/Input";
import InputType from "octa-front-sdk/dist/enums/InputType";
import Button from "octa-front-sdk/dist/components/Button";
import { validateEmail, validatePassword } from "octa-front-sdk/dist/Helpers";
import FormContext from "octa-front-sdk/dist/contexts/FormContext";
import RequestHandler from "octa-front-sdk/dist/RequestHandler";
import FullPageLoader from "../../components/FullPageLoader";
import { useHistory } from "react-router";
import GlobalContext from "../../contexts/GlobalContext";
import logo from "../../assets/images/logo.svg";

const LoginPage: React.FC = (): ReactElement => {
  const [isLoading, setLoading] = useState();
  const [email, setEmail] = useState("");
  const history = useHistory();
  const { setLoggedIn } = useContext(GlobalContext);

  const handleLogin = (email: string, password: string): void => {
    setLoading(true);

    RequestHandler.post(`auth/login`, { email, password })
      .then((response): void => {
        const data = response.data;

        Object.keys(data).map((key): void =>
          localStorage.setItem(key, data[key])
        );

        setLoggedIn(true);

        history.push("/");
      })
      .finally((): void => setLoading(false));
  };

  return (
    <>
      <LoginPageStyles.Container>
        <img src={logo} alt="logo" />

        <Form
          onSubmit={(values): void => {
            const email = values.email.toString();
            const password = values.password.toString();

            setEmail(email);

            handleLogin(email, password);
          }}
          initialValues={{ email, password: "" }}
        >
          <Input label="Email:" name="email" validator={validateEmail} />

          <Input
            label="Senha:"
            name="password"
            validator={validatePassword}
            type={InputType.Password}
          />

          <FormContext.Consumer>
            {({ hasErrors }): ReactElement => (
              <Button text="Entrar" isSubmit isDisabled={hasErrors} />
            )}
          </FormContext.Consumer>
        </Form>
      </LoginPageStyles.Container>

      {isLoading && <FullPageLoader />}
    </>
  );
};

export default LoginPage;

import React, { ReactElement, useEffect } from "react";
import FullPageLoaderStyles from "./styles";

const FullPageLoader: React.FC = (): ReactElement => {
  useEffect((): (() => void) => {
    document.body.style.overflow = "hidden";

    return (): void => {
      document.body.style.overflow = "initial";
    };
  }, []);

  return (
    <FullPageLoaderStyles.Container>
      <FullPageLoaderStyles.Message>Carregando</FullPageLoaderStyles.Message>
    </FullPageLoaderStyles.Container>
  );
};

export default FullPageLoader;

import { createContext } from "react";

export interface IGlobalContext {
  isLoggedIn: boolean;
  setLoggedIn: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

const GlobalContext = createContext<IGlobalContext>({
  isLoggedIn: false,
  setLoggedIn: (): void => {}
});

export default GlobalContext;

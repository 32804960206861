import styled from "styled-components";
import Color from "octa-front-sdk/dist/enums/Color";
import FontSize from "octa-front-sdk/dist/enums/FontSize";
import MediaQuery from "octa-front-sdk/dist/enums/MediaQuery";

const Title = styled.h1`
  margin: 20px 0 15px;
  font-size: ${FontSize.NormalX};

  ${MediaQuery.BiggerThanSmall} {
    font-size: ${FontSize.Big};
    margin-bottom: 25px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  span {
    font-size: ${FontSize.SmallX};

    &:last-of-type {
      font-weight: bold;
    }
  }
`;

const Column = styled.div`
  flex: 1;

  & > span {
    color: ${Color.Grey700};
    margin-bottom: 15px;
    display: inline-block;
  }

  &:first-of-type {
    border-bottom: 1px solid ${Color.Grey300};
    margin-bottom: 10px;
    padding-bottom: 10px;

    ${MediaQuery.BiggerThanSmall} {
      & {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
        border-right: 1px solid ${Color.Grey300};
        margin-right: 30px;
        padding-right: 30px;
      }
    }
  }
`;

const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${MediaQuery.BiggerThanSmall} {
    flex-direction: row;
  }
`;

const DigestStyles = {
  Title,
  Row,
  Column,
  ColumnsContainer
};

export default DigestStyles;

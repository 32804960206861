import React, { ReactElement } from "react";
import TopBar from "../TopBar";

const Page: React.FC = (props): ReactElement => {
  return (
    <>
      {props.children}

      <TopBar />
    </>
  );
};

export default Page;

import React, { ReactElement } from "react";
import DigestStyles from "./styles";
import { formatMoney } from "octa-front-sdk/dist/Helpers";
import Summary from "../../../types/Summary";

interface IProps {
  summaries: Summary[];
}

const Digest: React.FC<IProps> = (props): ReactElement => {
  const { summaries } = props;
  const values = summaries.map((s): number => parseFloat(s.value.toFixed(2)));
  const quantities = summaries.map((s): number => s.quantity);
  const totalValue = values.reduce((sum, v): number => sum + v, 0);
  const avgValue = totalValue / values.length;
  const minValue = Math.min.apply(Math, values);
  const maxValue = Math.max.apply(Math, values);
  const totalQuantity = quantities.reduce((sum, a): number => sum + a, 0);
  const avgQuantity = (totalQuantity / quantities.length).toFixed(2);
  const minQuantity = Math.min.apply(Math, quantities);
  const maxQuantity = Math.max.apply(Math, quantities);

  const incomeRows = [totalValue, avgValue, minValue, maxValue];
  const volumeRows = [totalQuantity, avgQuantity, minQuantity, maxQuantity];
  const labels = ["Total", "Média", "Mínimo", "Máximo"];

  return (
    <>
      <DigestStyles.Title>Resumo</DigestStyles.Title>

      <DigestStyles.ColumnsContainer>
        <DigestStyles.Column>
          <span>Faturamento</span>

          {labels.map(
            (label, i): ReactElement => (
              <DigestStyles.Row key={`income-row-${i}`}>
                <span>{`${label}:`}</span>

                <span>{`${formatMoney(incomeRows[i] * 100)}`}</span>
              </DigestStyles.Row>
            )
          )}
        </DigestStyles.Column>

        <DigestStyles.Column>
          <span>Quantidade</span>

          {labels.map(
            (label, i): ReactElement => (
              <DigestStyles.Row key={`volume-row-${i}`}>
                <span>{`${label}:`}</span>

                <span>{`${volumeRows[i]}`}</span>
              </DigestStyles.Row>
            )
          )}
        </DigestStyles.Column>
      </DigestStyles.ColumnsContainer>
    </>
  );
};

export default Digest;

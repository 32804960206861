import styled from "styled-components";
import FormStyles from "octa-front-sdk/dist/components/Form/styles";
import Color from "octa-front-sdk/dist/enums/Color";
import MediaQuery from "octa-front-sdk/dist/enums/MediaQuery";

const Container = styled.div`
  margin: 0 auto;
  text-align: center;

  img {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 300px;

    ${MediaQuery.BiggerThanSmall} {
      margin-bottom: 60px;
      width: 420px;
    }
  }

  ${FormStyles.Form} {
    max-width: 280px;
    margin: 0 auto;

    button {
      background: linear-gradient(
        45deg,
        ${Color.MeuDeliveryCyan},
        ${Color.MeuDeliveryBlue}
      );

      &:disabled {
        background: none;
        background-color: ${Color.Grey400};
      }
    }
  }
`;

const LoginPageStyles = { Container };

export default LoginPageStyles;
